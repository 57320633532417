$primary-color:#2b2d8e;
$secondary-color:#549D00;
$third-color: #ed353c;
$forth-color: #ffb101;
$color-white:#ffffff;
$color-dark:#2E2E2E;
$color-light:#4E4E4E;
$color-lighter:#8E8E8E;
$color-white-light: #F7F7F7;

// bg color
$bg-body: #f6f9ff;