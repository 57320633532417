.report_wrapper {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    .report_outer_box {
        max-width: 1000px;
        width: 100%;
        padding: 30px 40px;
        border-radius: 5px;
        margin: 0 auto;

        .report_inner_box {
            h1 {
                font-size: 30px;
                font-weight: 700;
                text-align: center;
                margin-bottom: 20px;
                color: #374151;
            }
            .report_check_wrap{
                padding: 20px;
                background-color: $primary-color;
                border-radius: 5px;
                .report_check_wrap_title{
                    color: $color-white;
                    margin-bottom: 0;
                }
                .btn_look_up{
                    height: 40px;
                    background-color: $third-color;
                    border-color: $third-color;
                    color: $color-white;
                }
            }
        }
    }
}

 @media (max-width:767px) {
     .report_check_wrap {
         text-align: center !important;
     }
 }