
// Margin css
.mb_0{
    margin-bottom: 0 !important;
}
.mb_1{
    margin-bottom: 0.5rem !important;
}
.mb_2{
    margin-bottom: 1rem !important;
}
.mb_3{
    margin-bottom: 1.5rem !important;
}
.mb_4{
    margin-bottom: 2rem !important;
}
.mb_5{
    margin-bottom: 2.5rem !important;
}

.mt_0{
    margin-top: 0 !important;
}
.mt_1{
    margin-top: 0.5rem;
}
.mt_2{
    margin-top: 1rem;
}
.mt_3{
    margin-top: 1.5rem;
}
.mt_4{
    margin-top: 2rem;
}
.mt_5{
    margin-top: 2.5rem;
}


.me_0 {
    margin-right: 0 !important;
}

.me_1 {
    margin-right: 0.5rem !important;
}

.me_2 {
    margin-right: 1rem !important;
}

.me_3 {
    margin-right: 1.5rem !important;
}

.me_4 {
    margin-right: 2rem !important;
}

.me_5 {
    margin-right: 2.5rem !important;
}

.ms_0 {
    margin-left: 0 !important;
}

.ms_1 {
    margin-left: 0.5rem !important;
}

.ms_2 {
    margin-left: 1rem !important;
}

.ms_3 {
    margin-left: 1.5rem !important;
}

.ms_4 {
    margin-left: 2rem !important;
}

.ms_5 {
    margin-left: 2.5rem !important;
}

.lh_0{
    line-height: 0;
}
.lh_1 {
    line-height: 1;
}
.lh_1_5 {
    line-height: 1.5;
}
.lh_2 {
    line-height: 2;
}



.text_center{
    text-align: center !important;
}
.text_left{
    text-align: left !important;
}
.text_right{
    text-align: right !important;
}
.text_justify{
    text-align: justify !important;
}
.text_dark{
    color: $color-dark;
}
.text_primary{
    color: $primary-color !important;
}
.text_success{
    color: $secondary-color !important;
}
.text_failed{
    color: $third-color !important;
}
.text_dark{
    color: $color-dark !important;
}
.position_relative{
    position: relative;
}

.br_5{
    border-radius: 5px;
}
.br_10 {
    border-radius: 5px;
}

