.btn-primary{
    color: $color-white-light;
    border-color: $primary-color;
    background: $primary-color;
    border-radius: 6px;

    &:hover{
        border-color: $primary-color;
        background: $primary-color;
        color: $color-white;
    }
    &:focus{
        border-color: $primary-color;
        background: $primary-color;
        color: $color-white;
    }
    &:focus-visible{
        outline: none;
    }    
}
.btn-login{
    min-width: 150px;
    height: 38px;
}
@media (max-width:360px) {
    .btn-login{
        min-width: 130px;
    }
}

.btn-outline-primary{
    color: $primary-color;
    border-color: $primary-color;
    background: $color-white;
    border-radius: 6px;

    &:hover{
        color: $primary-color;
        border-color: $primary-color;
        background: $color-white;
    }
    &:focus{
        color: $primary-color;
        border-color: $primary-color;
        background: $color-white;
    }
    &:focus-visible{
        outline: none;
    }    
}



.btn-logout{
    color: $color-white-light;
    border-color: $primary-color;
    background: $primary-color;
    box-shadow: 0 2px rgba(0, 0, 0, 0.043);

    &:hover{
        border-color: $primary-color;
        background: $primary-color;
        color: $color-white-light;
    }
    &:focus{
        border-color: $primary-color;
        background: $primary-color;
        color: $color-white-light;
    }
    &:focus-visible{
        outline: none;
    }
    .logout-icon{
        transform: rotate(270deg);
    }
}

.btn_anchor{
    line-height: 1.5715;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    border: 1px solid transparent;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    touch-action: manipulation;
    height: 32px;
    padding: 4px 15px;
    font-size: 14px;
    color: $color-white-light !important;
    border-color: $primary-color;
    background: $primary-color;
    // text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    // box-shadow: 0 2px rgb(0 0 0 / 4%);
    border-radius: 6px;
    text-decoration: none;
    &:hover{
        border-color: $primary-color;
        background: $primary-color;
        color: $color-white-light !important;
    }
    &:focus{
        border-color: $primary-color;
        background: $primary-color;
        color: $color-white-light !important;
    }
    &:focus-visible{
        outline: none;
    }
}
