.login_Wrapper{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background: url("../assets/images/bg_login.webp");
    background-repeat: no-repeat;
    background-size: cover;
    .login_outer_box{
        max-width: 420px;
        width: 100%;
        background-color: $color-white;
        padding: 30px 40px;
        border-radius: 5px;
        .login_inner_box{
            h1{
                font-size: 28px;
                font-weight: 700;
                text-align: center;
                margin-bottom: 30px;
                color: #374151;
            }
            .login-form{
                label{
                    font-size: 15px;
                }
                .ant-input{
                    height: 40px;
                    border-radius: 5px;
                    background-color: $color-white;
                    border: solid 1px #ced4da;
                    &:focus{
                        box-shadow: none;
                        border: solid 1px $primary-color;
                    }
                }
            }
        }
    }
    @media (max-width:480px) {
        .login_outer_box{
            padding: 15px;
        }
    }
}
@media (max-width:576px) {
    .login_Wrapper{
        padding: 15px;
    }
}